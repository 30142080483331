import { useNavigate } from 'react-router-dom'

const ProjectCard = ({ image }) => {
    const navigate = useNavigate()
    return (
        <div onClick={e => navigate(`/projects/${image.link}/`)} className='relative z-0 block w-64 shadow snap-center aspect-square h-96 hover:cursor-pointer'>
            <div className='w-full h-full overflow-hidden'>
                <div className='w-full h-full transition duration-300 bg-left-top bg-cover hover:scale-110' style={{ backgroundImage: `url(${image.src})` }}></div>
                <div className='absolute left-0 right-0 p-4 shadow-lg bottom-4 bg-stone-900/50 backdrop-blur'>
                    <div className='text-xl text-white'>{image.project}</div>
                    <div className='text-sm text-white'>{image.shortDescription}</div>
                </div>
            </div>
        </div>
    )
}

export default ProjectCard