import { useOutletContext } from 'react-router-dom'
import Stars from './Stars'
import { CubeIcon, TemplateIcon, GlobeAltIcon } from '@heroicons/react/outline'

const Experience = () => {

    const [darkMode] = useOutletContext()

    const skills = [
        {
            'category': 'Web Development',
            'icon': CubeIcon,
            'skills': {
                'Angular': 3,
                'Python': 2,
                'PHP': 5,
                'React': 4,
                'Vue': 2,
                'JavaScript': 4,
                'JQuery': 5,
                'Express': 3,
                'Restful API': 5,
                'XML/XSLT': 3,
                'HTML': 5,
                'CSS': 5,
                'Tailwind': 4,
                'Bootstrap': 4,
                'MySQL': 4,
                'PostgreSQL': 3,
                'SQLite': 4,
                'MongoDB': 3,
                'Prisma': 2,
            },
        },
        {
            'category': 'Design & UX',
            'icon': TemplateIcon,
            'skills': {
                'Surveying': 4,
                'User Stories': 3,
                'Wireframing': 4,
                'Prototyping': 5,
                'User Testing': 3,
                'Photoshop': 3,
                'Axure RP': 5,
                'Identity': 3,
                'Layouts': 4,
                'Web Design': 4,
                'Whimsical': 4,
                'Figma': 2,
            }
        },
        {
            'category': 'Web Management',
            'icon': GlobeAltIcon,
            'skills': {
                'SEO': 3,
                'Analytics': 4,
                'Marketing': 4,
                'Hubspot': 3,
                'Wordpress': 3,
                'Drupal': 2,
                'Docker': 2,
                'Git': 2,
                'Lando': 2,
                'Zapier': 2,
                'Supabase': 2,
                'Vercel': 2,
            }
        },
    ]

    return (
        <>
            <h2 className='my-8 text-4xl sm:my-16 dark:text-stone-100 text-stone-700'>Skills</h2>
            {
                skills.map(category => {
                    const Icon = category.icon
                    return (
                        <div key={category.category} className='my-16'>
                            <h3 className='flex flex-row gap-4 mb-4 text-2xl dark:text-sky-300 text-sky-900'><Icon className='w-8 h-8' />{category.category}</h3>
                            <ul className='text-base dark:text-stone-100 columns-xs '>{
                                Object.keys(category.skills).map(skill => {
                                    const color = darkMode ? `text-amber-400` : `text-sky-400`
                                    return (<li key={skill} className='flex flex-row items-center gap-1 leading-loose'><div className='w-32'>{skill}</div><Stars count={category.skills[skill]} color={color} /></li>)
                                })
                            }</ul>
                        </div>
                    )
                })
            }
        </>
    )
}

export default Experience