import { CalendarIcon, LocationMarkerIcon } from '@heroicons/react/outline'

const Experience = () => {

    const experience = [
        {
            role: 'Freelance Developer',
            company: 'Self-Employed',
            url: '',
            location: 'Remote',
            date: 'June 2020 - Present',
            description: 'Full-stack developer of web and mobile applications, responsive user interfaces, information retrieval/ai bots and content management systems.',
            skills: [
                'HTML',
                'CSS',
                'Tailwind',
                'JavaScript',
                'React',
                'JQuery',
                'PHP',
                'MySQL',
                'SQLite',
                'REST API',
                'JSON',
                'Data Mining',
                'CMS',
            ]
        },
        {
            role: 'Senior Software Engineer',
            company: 'Kyros Digital',
            url: 'https://kyrosdigital.com/',
            location: 'Grand Rapids, Michigan',
            date: 'Oct 2021 - Feb 2023',
            description: 'Enterprise full stack web development using industry-leading technologies.',
            skills: [
                'React',
                'Next.js',
                'Vue.js',
                'JavaScript',
                'JQuery',
                'Tailwind',
                'Bootstrap',
                'Drupal',
                'Git/GitHub',
                'Lando',
                'Docker',
                'SugarCRM',
                'Zapier',
                'HTML',
                'CSS',
                'PostgreSQL',
                'MySQL',
                'REST API',
                'Tableau',
                'Express',
                'MongoDB',
                'Whimsical',
                'Figma'
            ]
        },
        {
            role: 'Web Manager',
            company: 'Checkmarx',
            url: 'https://checkmarx.com/',
            location: 'Tel Aviv, Israel (Remote)',
            date: 'March 2019 - May 2020',
            description: 'Marketing technology leader. Conducted major redesign of company\'s website. Developed and maintained web applications and web management platforms. Monitored and corrected technical and performance issues with web infrastructure.',
            skills: [
                'PHP',
                'HTML',
                'CSS',
                'JavaScript',
                'JQuery',
                'Hubspot',
                'Wordpress',
                'YoastSEO',
                'YouTube Studio',
                'Git/Github',
                'Google Analytics',
                'Google Tag Manager',
                'AWS',
                'CloudFront',
                'Photoshop',
                'Trello',
            ]
        },
        {
            role: 'Senior Front End Developer',
            company: 'TRUX',
            url: 'https://truxnow.com/',
            location: 'Waltham, Massachusetts',
            date: 'July 2018 - January 2019',
            description: 'Developed web user interfaces for a trucking marketplace and logistics management system. Built user interface components using AngularJS. Authored the Developer Style Guide. Provided user experience design leadership.',
            skills: [
                'AngularJS',
                'Bootstrap',
                'HTML',
                'CSS',
                'Sketch',
                'Axure RP',
            ]
        },
        {
            role: 'Lead Front End Developer',
            company: 'Powerhouse Dynamics',
            url: 'https://powerhousedynamics.com/',
            location: 'Newton, Massachusetts',
            date: 'August 2015 - June 2018',
            description: 'Designed, built and maintained web-based software systems. Developed advanced monitoring and control interfaces for smart equipment and IoT sensors. Planned and executed user experience research initiatives.',
            skills: [
                'PHP',
                'JavaScript',
                'JQuery',
                'HTML',
                'CSS',
                'MySQL',
                'Axure RP',
            ]
        },
        {
            role: 'User Experience Director',
            company: 'Stealth Startup',
            url: '',
            location: 'Boston, Massachusetts',
            date: 'April 2015 - July 2015',
            description: 'User experience design leader for an early stage E-Commerce startup.',
            skills: [
                'Competitive Analysis',
                'User Surveys',
                'User Personas',
                'Task Flows',
                'Wireframes',
                'Interactive Prototypes',
            ]
        },
        {
            role: 'Software Engineer',
            company: 'Wayfair',
            url: 'https://wayfair.com/',
            location: 'Boston, Massachusetts',
            date: 'August 2013 - January 2015',
            description: 'Developed and maintained the Europe Logistics Extranet, a platform to integrate Wayfair\'s order management system with external suppliers and logistics companies across Europe. Designed and created product catalogs and specialty interfaces for daily sales brand Joss & Main.',
            skills: [
                'PHP',
                'MySQL',
                'JavaScript',
                'HTML',
                'CSS',
                'REST API',
                'ASP.net',
                'Solr',
                'Git',
            ]
        },
        {
            role: 'Principal Developer',
            company: '404 software',
            url: '',
            location: 'Culebra, Puerto Rico',
            date: 'January 2011 - August 2013',
            description: 'Developed custom web applications for small businesses and non-profits. Applications included genealogy archive, library records, real estate management, reservation systems and data management solutions.',
            skills: [
                'PHP',
                'Python',
                'MySQL',
                'XML/XSLT',
                'SQLite',
                'HTML',
                'CSS',
                'JavaScript',
                'JQuery',
                'Wordpress',
                'Data Mining',
            ]
        },
        {
            role: 'Applications Systems Analyst',
            company: 'University of Arizona',
            url: 'https://hr.arizona.edu/',
            location: 'Tucson, Arizona',
            date: 'September 2010 - December 2010',
            description: 'Conducted major redesign of the HR department\'s public website and intranet portal. Developed Drupal content management applications and modules. Managed and maintained Intranet and Internet websites for 6 Human Resources sub-departments.',
            skills: [
                'PHP',
                'MySQL',
                'Drupal 6',
                'OpenAtrium',
                'Linux',
                'Web Design',
                'HTML',
                'CSS',
            ]
        },
        {
            role: 'Founder, Lead Designer',
            company: 'Allen Design',
            url: '',
            location: 'Charlottesville, Virginia',
            date: 'July 2004 - September 2010',
            description: 'Founder and lead designer of a namesake website design firm. Created and maintained over 100 web properties, specializing in e-commerce and marketing websites for small businesses. Managed a globally-dispersed team of contract designers and developers.',
            skills: [
                'HTML',
                'CSS',
                'JavaScript',
                'PHP',
                'Python',
                'Photoshop',
                'Logo Design',
                'Web Design',
            ]
        },
        {
            role: 'Webmaster, Lead Web Designer',
            company: 'St. Augustine Record',
            url: 'https://staugustine.com/',
            location: 'St. Augustine, Florida',
            date: 'July 2000 - September 2002',
            description: 'Managed and maintained the international award-winning website for a daily local newspaper. Conducted a major website redesign. Assisted in developing the daily publishing platform and created automated publishing tools. Designed and built over 30 commercial websites.',
            skills: [
                'HTML',
                'JavaScript',
                'CSS',
                'Photoshop',
                'Photography',
                'Video Production',
                'FileMaker',
                'Perl',
                'Linux',
                'Web Design',
            ]
        },
    ]

    return (
        <>
            <h2 className='mt-24 -mb-8 text-4xl dark:text-stone-100 text-stone-700'>Experience</h2>
            {
                experience.map(exp => {
                    const company = exp.url !== '' ? (<a href={exp.url} target='_blank' rel='noreferrer' className='underline hover:text-amber-400'>{exp.company}</a>) : exp.company
                    return (
                        <div key={exp.company} className='my-24'>
                            <div className='flex flex-col items-start gap-8 mb-8 sm:mt-8 sm:gap-0 sm:items-center sm:flex-row'>
                                <div className='sm:w-1/2'>
                                    <h3 className='text-xl sm:text-2xl dark:text-sky-300 text-sky-900'>{exp.role}</h3>
                                    <h4 className='text-xl sm:text-2xl dark:text-stone-200 text-sky-900'>{company}</h4>
                                </div>
                                <div className='sm:w-1/2'>
                                    <h5 className='flex flex-row gap-1 mb-1 dark:text-stone-400'><LocationMarkerIcon className='w-5 h-5' />{exp.location}</h5>
                                    <h5 className='flex flex-row gap-1 dark:text-stone-400'><CalendarIcon className='w-5 h-5' />{exp.date}</h5>
                                </div>
                            </div>
                            <p className='mb-8 font-light leading-relaxed sm:text-lg dark:text-white text-sky-900'>{exp.description}</p>
                            <ul className='text-base leading-loose dark:text-stone-100 columns-2 sm:columns-4'>{
                                exp.skills.map(skill => {
                                    return (<li key={skill}>{skill}</li>)
                                })
                            }</ul>
                        </div>
                    )
                })
            }
        </>
    )
}

export default Experience