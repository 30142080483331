import projectData from './projectData'
import ProjectCard from './ProjectCard'

const Projects = () => {
    return (
        <>
            <div className='grid justify-center gap-16 pt-8 sm:pt-0 sm:grid-cols-3'>
                {
                    Object.keys(projectData).map((project, index) => {
                        const image = {
                            project: projectData[project].project,
                            link: project,
                            src: projectData[project].images[0].src,
                            shortDescription: projectData[project].shortDescription
                        }
                        return (
                            <ProjectCard key={index} image={image} />
                        )
                    })
                }
            </div>
        </>
    )
}

export default Projects