import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Carousel from './Carousel'
import projectData from './projectData'
import { CalendarIcon, LocationMarkerIcon, LinkIcon, UsersIcon, ChipIcon, ShareIcon } from '@heroicons/react/outline'

const Project = () => {

    const navigate = useNavigate()
    const params = useParams()

    const [project, setProject] = React.useState()

    React.useEffect(() => {
        if (!params.id || !Object.keys(projectData).includes(params.id)) {
            navigate('/404')
        }
        setProject(projectData[params.id])
    }, [navigate, params.id])

    return project && (
        <>
            <div className='flex flex-col gap-8 mb-8 sm:gap-0 sm:mt-8 sm:items-center sm:flex-row'>
                <div className='sm:w-1/2'>
                    <h3 className='text-3xl sm:text-5xl dark:text-sky-300 text-sky-900'>{project.project}</h3>
                    <h4 className='text-lg sm:text-xl dark:text-stone-200 text-sky-900'>{project.shortDescription}</h4>
                </div>
                <div className='sm:w-1/2'>
                    {project.url && <h5 className='flex flex-row gap-1 mb-1 dark:text-stone-400'><LinkIcon className='w-5 h-5' /><a href={project.url} target='_blank' rel='noreferrer' className='underline hover:text-amber-400'>{project.url.replace('https://', '')}</a></h5>}
                    <h5 className='flex flex-row gap-1 mb-1 dark:text-stone-400'><LocationMarkerIcon className='w-5 h-5' />{project.location}</h5>
                    <h5 className='flex flex-row gap-1 dark:text-stone-400'><CalendarIcon className='w-5 h-5' />{project.date}</h5>
                </div>
            </div>
            <h3 className='flex flex-row gap-1 mb-4 text-2xl dark:text-sky-300 text-sky-900'><UsersIcon className='w-8 h-8' />Roles</h3>
            <ul className='mb-8 text-base leading-loose dark:text-stone-100 columns-2 sm:columns-4'>{
                project.roles.map(role => {
                    return (<li key={role}>{role}</li>)
                })
            }</ul>
            <h3 className='flex flex-row gap-1 mb-4 text-2xl dark:text-sky-300 text-sky-900'><ChipIcon className='w-8 h-8' />Tech</h3>
            <ul className='mb-8 text-base leading-loose dark:text-stone-100 columns-2 sm:columns-4'>{
                project.tech.map(tech => {
                    return (<li key={tech}>{tech}</li>)
                })
            }</ul>
            <h3 className='flex flex-row gap-1 mb-4 text-2xl dark:text-sky-300 text-sky-900'><ShareIcon className='w-8 h-8' />Tags</h3>
            <ul className='text-base leading-loose dark:text-stone-100 columns-2 sm:columns-4'>{
                project.tags.map(tag => {
                    return (<li key={tag}>{tag}</li>)
                })
            }</ul>
            <div className='mx-auto my-16 sm:my-24'>
                <Carousel images={project.images} />
            </div>
            {
                project.description.map((desc, key) => {
                    return (<p key={key} className='mb-8 font-light leading-relaxed sm:text-lg dark:text-white text-sky-900'>{desc}</p>)
                })
            }
        </>
    )
}

export default Project