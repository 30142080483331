import Download from './Download'
import Skills from './Skills'
import Education from './Education'
import Experience from './Experience'

const Resume = () => {
    return (
        <>
            <Download />
            <Experience />
            <Education />
            <Skills />
        </>
    )
}

export default Resume