import React from 'react'

const useLocalStorage = (key, state) => {
    const [value, setValue] = React.useState(
        JSON.parse(localStorage.getItem(key)) ?? state
    )
    React.useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value))
    }, [value, key])
    return [value, setValue]
}

export default useLocalStorage