const projectData = {
    'vanspot': {
        project: 'VanSpot',
        url: 'https://vanspot.org',
        location: 'Milwaukee, WI',
        date: '2021',
        description: [
            'Vanspot is a "Roadlife Navigator" - a data-rich map made to simplify living on the road.',
            'A User may browse and search any US location for over 70,000 amenities, shopping, lodging, and travel services. Matches appear as small signs on the map.',
            'The location\'s current and 10-day forecast weather conditions can also be viewed.',
            'The frontend is built in Angular utilizing MapBox, OpenStreetMap and Leaflet libraries. Behind-the-scenes API work is done in PHP with SQLite DBs. Data mining and web-scraping is accomplished using custom web-crawling scripts.'
        ],
        shortDescription: 'Roadlife Navigator',
        roles: [
            'Designer',
            'Developer',
        ],
        images: [
            {
                src: '/images/vanspot_map_new.png',
                alt: '',
            },
            {
                src: '/images/vanspot_detail.png',
                alt: '',
            },
            {
                src: '/images/vanspot_options.png',
                alt: '',
            },
            {
                src: '/images/vanspot_weather.png',
                alt: '',
            },
        ],
        tech: [
            'PHP',
            'REST API',
            'Bootstrap',
            'Angular',
            'Javascript',
            'CSS',
            'HTML',
            'Web Scraping',
            'Leaflet.js',
            'MapBox',
            'OpenStreetMap',
            'OpenWeatherMap',
        ],
        tags: [
            'Web App',
            'Database',
            'Branding',
        ],
    },
    'russet': {
        project: 'Russet',
        url: 'https://russet.app',
        location: 'Milwaukee, WI',
        date: '2022',
        description: [''],
        shortDescription: 'Knowledge Organizer',
        roles: [
            'Designer',
            'Developer',
        ],
        images: [
            {
                src: '/images/russet_editor.png',
                alt: '',
            },
            {
                src: '/images/russet_lyrics.png',
                alt: '',
            },
            {
                src: '/images/russet_mobile.png',
                alt: '',
            },
            {
                src: '/images/russet_search.png',
                alt: '',
            },
        ],
        tech: [
            'React',
            'Javascript',
            'CSS',
            'HTML',
            'Tailwind',
            'LocalStorage',
        ],
        tags: [
            'Web App',
        ],
    },
    'capespot': {
        project: 'CapeSpot',
        url: 'https://capespot.com',
        location: 'Cape Cod, Massachusetts',
        date: '2020',
        description: [''],
        shortDescription: 'Dining Guide',
        roles: [
            'Designer',
            'Developer',
        ],
        images: [
            {
                src: '/images/capespot_front.png',
                alt: 'CapeSpot',
            },
            {
                src: '/images/capespot_detail.png',
                alt: 'CapeSpot',
            },
            {
                src: '/images/capespot_list.png',
                alt: 'CapeSpot',
            },
            {
                src: '/images/capespot_restaurant.png',
                alt: 'CapeSpot',
            },
            {
                src: '/images/capespot_towns.png',
                alt: 'CapeSpot',
            },
        ],
        tech: [
            'PHP',
            'Javascript',
            'Zepto.js',
            'Ruse.js',
            'CSS',
            'HTML',
            'Mustache',
            'MapQuest',
            'SQLite',
            'REST API',
            'Web Scraping',
        ],
        tags: [
            'Web App',
            'App Design',
            'Database',
        ],
    },
    'casalibros': {
        project: 'CasaLibros',
        url: 'https://casalibros.com',
        location: 'Boston, Massachusetts',
        date: '2019',
        description: [''],
        shortDescription: 'E-book Library',
        roles: [
            'Designer',
            'Developer',
        ],
        images: [
            {
                src: '/images/casalibros_book.png',
                alt: 'CasaLibros',
            },
            {
                src: '/images/casalibros_books.png',
                alt: 'CasaLibros',
            },
            {
                src: '/images/casalibros_subjects.png',
                alt: 'CasaLibros',
            },
            {
                src: '/images/casalibros_subject.png',
                alt: 'CasaLibros',
            },
            {
                src: '/images/casalibros_author.png',
                alt: 'CasaLibros',
            },
            {
                src: '/images/casalibros_paging.png',
                alt: 'CasaLibros',
            },
        ],
        tech: [
            'PHP',
            'Javascript',
            'Zepto.js',
            'Ruse.js',
            'CSS',
            'HTML',
            'Mustache',
            'SQLite',
            'REST API',
            'Web Scraping',
            'Gutenberg API',
            'GoodReads API',
        ],
        tags: [
            'Web App',
            'App Design',
            'Database',
            'Branding',
        ],
    },
    'belaycafe': {
        project: 'Belay Cafe',
        url: 'https://belay.cafe',
        location: 'Boston, Massachusetts',
        date: '2019',
        description: [''],
        shortDescription: 'Climbing Gym Directory',
        roles: [
            'Designer',
            'Developer',
        ],
        images: [
            {
                src: '/images/belaycafe_search.png',
                alt: 'Belay Cafe',
            },
            {
                src: '/images/belaycafe_detail.png',
                alt: 'Belay Cafe',
            },
            {
                src: '/images/belaycafe_detail2.png',
                alt: 'Belay Cafe',
            },
        ],
        tech: [
            'PHP',
            'SQLite',
            'Ruse.js',
            'Javascript',
            'JQuery',
            'Mustache',
            'HTML',
            'CSS',
            'Photoshop',
            'Web Scraping',
        ],
        tags: [
            'Web App',
            'Database',
            'Branding',
        ],
    },
    'mkeshows': {
        project: 'MKE Shows',
        url: 'https://mke.show',
        location: 'Milwaukee, Wisconsin',
        date: '2021',
        description: [
            'MKE Shows is used to find concerts, shows and new music in and around Milwaukee, Wisconsin.',
            'Users can find upcoming shows organized by date, venue and genre. Custom clustering algorithms suggest similar artists, venues and genres based on music service and crowdsourced metrics.',
            'Venue details include map and directions provided by MapQuest. Artist detail include album art, genre and top tracks with track samples from Spotify. Concert and Venue data sourced from Songkick API integration.',
            'Built using AngularJS on the frontend with a RESTful API written in PHP and a MySQL database.'
        ],
        shortDescription: 'Concert Finder',
        roles: [
            'Designer',
            'Developer',
        ],
        images: [
            {
                src: '/images/mkeshow_shows.png',
                alt: '',
            },
            {
                src: '/images/mkeshow_show.png',
                alt: '',
            },
            {
                src: '/images/mkeshow_artist.png',
                alt: '',
            },
            {
                src: '/images/mkeshow_tracks.png',
                alt: '',
            },
            {
                src: '/images/mkeshow_venue.png',
                alt: '',
            },
            {
                src: '/images/mkeshow_genres.png',
                alt: '',
            },
            {
                src: '/images/mkeshow_genre.png',
                alt: '',
            },
        ],
        tech: [
            'PHP',
            'MySQL',
            'Angular',
            'Javascript',
            'HTML',
            'CSS',
            'REST API',
            'Web Scraping',
            'MapQuest',
        ],
        tags: [
            'Web App',
            'Database',
            'API Mashup',
        ],
    },
    'massrocks': {
        project: 'Mass Rocks',
        url: 'https://mass.rocks',
        location: 'Boston, Massachusetts',
        date: '2016',
        description: [''],
        shortDescription: 'Concert Finder',
        roles: [
            'Designer',
            'Developer',
        ],
        images: [
            {
                src: '/images/mass_rocks_band.png',
                alt: '',
            },
            {
                src: '/images/mass_rocks_tracks.png',
                alt: '',
            },
            {
                src: '/images/mass_rocks_venue.png',
                alt: '',
            },
        ],
        tech: [
            'PHP',
            'MySQL',
            'Angular',
            'Javascript',
            'HTML',
            'CSS',
            'REST API',
            'Web Scraping',
            'MapQuest API',
            'Spotify API',
            'Songkick API',
        ],
        tags: [
            'Web App',
            'Database',
        ],
    },
    'webdex': {
        project: 'Webdex',
        url: 'https://webdex.org',
        location: 'Boston, MA',
        date: '2019',
        description: [''],
        shortDescription: 'Bookmark Utility',
        roles: [
            'Designer',
            'Developer',
        ],
        images: [
            {
                src: '/images/webdex_tile.png',
                alt: '',
            },
            {
                src: '/images/webdex_list.png',
                alt: '',
            },
            {
                src: '/images/webdex_list2.png',
                alt: '',
            },
        ],
        tech: [
            'PHP',
            'MySQL',
            'Angular',
            'Javascript',
            'JQuery',
            'AJAX',
            'HTML',
            'CSS',
            'Mustache',
            'Web Scraping',
            'REST API',
        ],
        tags: [
            'Web App',
            'Database',
        ],
    },
    'kingsdice': {
        project: 'King\'s Dice',
        url: 'https://kingsdice.com',
        location: 'Cape Cod, Massachusetts',
        date: '2020',
        description: [''],
        shortDescription: 'Dice Game',
        roles: [
            'Developer',
            'Designer',
        ],
        images: [
            {
                src: '/images/kingsdice_bet.png',
                alt: '',
            }
        ],
        tech: [
            'HTML',
            'CSS',
            'Javascript',
            'Zepto.js',
        ],
        tags: [
            'Game Design',
        ],
    },
    'swipe-gifts': {
        project: 'Swipe Gifts',
        url: '',
        location: 'Boston, MA',
        date: '2015',
        description: [''],
        shortDescription: 'Gift Finder',
        roles: [
            'Designer',
        ],
        images: [
            {
                src: '/images/swipe_gifts_list.png',
                alt: '',
            }
        ],
        tech: [
            'Axure RP',
        ],
        tags: [
            'Prototype',
            'User Experience',
            'E-Commerce',
        ],
    },
    'swipe-recipes': {
        project: 'Swipe Recipes',
        url: '',
        location: 'Boston, MA',
        date: '2015',
        description: [''],
        shortDescription: 'Kitchen Helper',
        roles: [
            'Designer',
        ],
        images: [
            {
                src: '/images/swipe_recipes_home.png',
                alt: '',
            }
        ],
        tech: [
            'Axure RP',
        ],
        tags: [
            'Prototype',
            'User Experience',
        ],
    },
}

export default projectData