import { CalendarIcon, LocationMarkerIcon } from '@heroicons/react/outline'

const Education = () => {

    const education = [
        {
            prospectus: 'User Experience Design',
            institution: 'General Assembly',
            location: 'Boston, Massachusetts',
            date: 'November 2014 - March 2015',
        },
        {
            prospectus: 'Forestry/Geology',
            institution: 'Piedmont Virginia Community College',
            location: 'Charlottesville, Virginia',
            date: 'January 2004 - April 2006',
        },
        {
            prospectus: 'Business/Computer Science',
            institution: 'St. John\'s River State College',
            location: 'St. Augustine, Florida',
            date: 'January 1998 - May 2000',
        },
    ]

    return (
        <>
            <h2 className='my-16 text-4xl dark:text-stone-100 text-stone-700'>Education</h2>
            {
                education.map(exp => {
                    return (
                        <div key={exp.institution} className='flex flex-col items-start gap-8 mb-16 sm:items-center sm:gap-0 sm:flex-row'>
                            <div className='sm:w-1/2'>
                                <h3 className='mb-1 text-xl dark:text-sky-300 text-sky-900'>{exp.prospectus}</h3>
                                <h4 className='text-lg dark:text-stone-200 text-sky-900'>{exp.institution}</h4>
                            </div>
                            <div className='sm:w-1/2'>
                                <h5 className='flex flex-row gap-1 mb-1 dark:text-stone-400'><LocationMarkerIcon className='w-5 h-5' />{exp.location}</h5>
                                <h5 className='flex flex-row gap-1 dark:text-stone-400'><CalendarIcon className='w-5 h-5' />{exp.date}</h5>
                            </div>
                        </div>
                    )
                })
            }
        </>
    )
}

export default Education