import { DocumentDuplicateIcon } from '@heroicons/react/outline'

const Download = () => {
    return (
        <div className='flex flex-row items-center gap-2 text-xl dark:text-sky-300 text-sky-900'>
            <DocumentDuplicateIcon className='w-8 h-8' />
            <a href='./Wes Allen Resume 2024.pdf'>Download PDF</a>
        </div>
    )
}

export default Download