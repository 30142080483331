import { StarIcon } from '@heroicons/react/solid'

const Stars = ({ count, color }) => {
    const output = []
    for (let index = 0; index < count; index++) {
        output.push(<StarIcon key={index} className={`h-5 w-5 ${color}`} />)
    }
    return output
}

export default Stars