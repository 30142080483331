import React, { useState } from 'react'
import CarouselCard from './CarouselCard'

const Carousel = ({ images }) => {
    const [activePhoto, setActivePhoto] = useState(false)
    return (
        <div>
            {
                activePhoto && (
                    <div className='fixed top-0 left-0 z-10 flex items-center justify-center w-full h-full opacity-100'>
                        <div onClick={() => setActivePhoto(false)} className='absolute z-10 w-full h-full cursor-pointer bg-sky-50 dark:bg-stone-900 opacity-80'></div>
                        <div onClick={() => setActivePhoto(false)} className='z-50 p-2 mx-2 overflow-y-auto bg-white shadow-lg cursor-pointer md:p-4 dark:bg-white'>
                            <img src={`${activePhoto}`} alt='A' className={`w-full max-w-[100%] max-h-[800px]`} />
                        </div>
                    </div>
                )
            }
            <div className={`z-0 flex gap-16 overflow-x-auto scrollbar scrollbar-track-transparent scrollbar-thumb-stone-400`}>
                {
                    images.map((image, index) => {
                        return <CarouselCard key={index} image={image} setActivePhoto={setActivePhoto} />
                    })
                }
            </div>
        </div>
    )
}

export default Carousel