import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Layout from './Layout'
import Home from './Home'
import Resume from './Resume'
import Projects from './Projects'
import Project from './Project'
import NotFound from './NotFound'

const App = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path='/' element={<Home />} />
        <Route path='resume' element={<Resume />} />
        <Route path='projects'>
          <Route index={true} element={<Projects />} />
          <Route path=':id' element={<Project />} />
        </Route>
        <Route path='/404' element={<NotFound />} />
        <Route path='*' element={<NotFound />} />
      </Route>
    </Routes>
  )
}
export default App