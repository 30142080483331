import React from 'react'
import { Outlet } from 'react-router-dom'
import Navigation from './Navigation'
import useLocalStorage from './useLocalStorage'

const Layout = () => {

    const [darkMode, setDarkMode] = useLocalStorage('darkMode', true)

    return (
        <div className={`${darkMode ? 'dark' : ''}`}>
            <Navigation />
            <div className={`${darkMode ? 'wallpaper-dark' : 'wallpaper'} h-screen min-h-screen bg-sky-100 dark:bg-stone-900 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-stone-400`}>
                <div className='container max-w-screen-lg min-h-screen px-4 py-20 mx-auto sm:py-32 sm:px-16 bg-sky-50/50 dark:bg-stone-800/50'>
                    <Outlet context={[darkMode, setDarkMode]} />
                </div>
            </div>
        </div>
    )
}

export default Layout