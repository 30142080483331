import { useOutletContext } from 'react-router-dom'
import Projects from './Projects'

const Home = () => {
    const [, setDarkMode] = useOutletContext()
    return (
        <div className='sm:-mb-32'>
            <div className='flex flex-col items-center gap-4 sm:gap-16 sm:ml-16 sm:flex-row'>
                <img onClick={e => { setDarkMode(prev => !prev) }} src='./images/Wes_Allen.png' alt='Wes Allen' className='w-56 h-56 rounded-full shadow sm:w-64 sm:h-64' />
                <div>
                    <div className='mb-4 text-xl sm:text-3xl dark:text-amber-400 text-sky-900'>Wes Allen =&gt; <b>Web Builder</b></div>
                    <p className='mb-4 sm:pr-16 sm:text-lg dark:text-white'>Over 22 years of experience designing, developing and managing digital projects.</p>
                    <p className='mb-4 sm:pr-16 sm:text-lg dark:text-white'>Focused on front-end development with a full-stack and product design skillset.</p>
                    <p className='sm:pr-16 sm:text-lg dark:text-white'>Based in Casper, Wyoming and currently seeking my next remote opportunity.</p>
                </div>
            </div>
            <div className='hidden my-24 sm:block'>
                <Projects />
            </div>
        </div>
    )
}

export default Home