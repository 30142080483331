import { NavLink } from 'react-router-dom'
import { TerminalIcon, DatabaseIcon, CodeIcon } from '@heroicons/react/outline'

const Navigation = () => {
    const activeStyle = 'text-amber-200 dark:text-amber-400 flex flex-row gap-1 py-1 px-2 outline-none focus:ring-inset focus:ring-1 focus:ring-amber-400'
    const inactiveStyle = 'text-white flex flex-row gap-1 py-1 px-2 hover:text-amber-200 outline-none focus:ring-inset focus:ring-1 focus:ring-amber-400'
    return (
        <div className='fixed top-0 left-0 right-0 z-10 flex flex-row items-center justify-center h-16 gap-4 text-sm sm:text-base dark:bg-stone-900/80 bg-sky-900/80 backdrop-blur'>
            <NavLink to='/' className={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}><DatabaseIcon className='w-5 h-5' /> Home</NavLink>
            <NavLink to='/projects' className={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}><TerminalIcon className='w-5 h-5' /> Projects</NavLink>
            <NavLink to='/resume' className={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}><CodeIcon className='w-5 h-5' /> Resume</NavLink>
        </div>
    )
}

export default Navigation